<template>
  <div class="currencies">
    <v-select
      style="min-width: 100px"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      label="name"
      :options="whitelabelsOptionsState"
      @input="selectedWhitelabel"
      :value="$store.state.whitelabelCurrencyNabvar.whitelabel"
      :clearable="false"
    />
    <v-select
      style="min-width: 100px"
      :value="$store.state.whitelabelCurrencyNabvar.currency"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      label="name"
      :options="currenciesOptions"
      @input="selectCurrency"
      :clearable="false"
    />
  </div>
</template>

<script>
import { BRow, BCol, BContainer } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
    BRow,
    BCol,
    BContainer,
  },
  data: () => ({
    whitelabelSelect: {},
    currencieSelect: {},
  }),
  methods: {
    selectedWhitelabel(whitelabel) {
      console.log(this.$store.state.user.user, "this.$store.state.user.user")
      const payload = {
        whitelabel: whitelabel,
        userLogueado: this.$store.state.user.user,
      }
      this.$store.commit('whitelabelCurrencyNabvar/SELECT_WHITELABEL', payload)
      // Save the selected whitelabel to localStorage
      localStorage.setItem('selectedWhitelabelId', whitelabel._id)
    },
    selectCurrency(currency) {
      this.$store.commit('whitelabelCurrencyNabvar/SELECT_CURRENCY', currency)
    },
  },
  computed: {
    currenciesOptions(){
      return  this.$store.getters['whitelabelCurrencyNabvar/CURRENCY_OPTIONS']
    },
    whitelabelsOptionsState(){
      // getter
      const whitelabels = this.$store.getters['whitelabelCurrencyNabvar/WHITELABELS_OPTIONS']
      if(whitelabels.length > 0){
        return whitelabels.filter(item => item.client !== null)
      }else{
        return []
      }
    },
  },
  mounted() {
    // Check if there is a whitelabel id saved in localStorage
    const savedWhitelabelId = localStorage.getItem('selectedWhitelabelId')
    if (savedWhitelabelId) {
      const selectedWhitelabel = this.whitelabelsOptionsState.find(
          whitelabel => whitelabel._id === savedWhitelabelId
      )
      if (selectedWhitelabel) {
        const payload = {
          whitelabel: selectedWhitelabel,
          userLogueado: this.$store.state.user.user,
        }
        this.$store.commit('whitelabelCurrencyNabvar/SELECT_WHITELABEL', payload)
      }
    } else {
      const payload = {
        whitelabel: this.whitelabelsOptionsState[0],
        userLogueado: this.$store.state.user.user,
      }
      this.$store.commit('whitelabelCurrencyNabvar/SELECT_WHITELABEL', payload )
    }
  },
}
</script>

<style lang="scss" scoped>
// @import '@core/scss/vue/libs/vue-select.scss';
@import 'src/assets/scss/mixin.scss';

.currencies {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;

  @include r(sm){
    flex-direction: row;
  }
}
</style>
